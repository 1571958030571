var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h1", { staticClass: "sr-only" }, [_vm._v("Twitch Accounts")]),
      _c(
        "rtb-card",
        [
          _c("rtb-card-body", [
            _c(
              "div",
              { staticClass: "rtb-d-flex align-center" },
              [
                _c(
                  "rtb-button",
                  {
                    staticClass: "mr-2",
                    attrs: { color: "success" },
                    on: { click: _vm.initTwitchAccountCreation },
                  },
                  [
                    _c("svg-icon", { attrs: { name: "plus-regular" } }),
                    _vm._v("Add new Twitch Account"),
                  ],
                  1
                ),
                _c("rtb-text-input", {
                  attrs: {
                    label: "Search",
                    placeholder: "Search",
                    "hide-label": "",
                  },
                  model: {
                    value: _vm.search,
                    callback: function ($$v) {
                      _vm.search = $$v
                    },
                    expression: "search",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("rtb-table"),
      _c("v-data-table", {
        staticClass: "rtb-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.getTwitchAccounts,
          pagination: _vm.pagination,
          search: _vm.search,
          "item-key": "key",
        },
        on: {
          "update:pagination": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("tr", [
                  _c("td", [_vm._v(" " + _vm._s(item.name) + " ")]),
                  _c("td", [_vm._v(_vm._s(item.url))]),
                  _c("td", [_vm._v(_vm._s(item.apiKey))]),
                  _c("td", [_vm._v(_vm._s(item.status || "unavailable"))]),
                  _c(
                    "td",
                    { attrs: { width: "108px" } },
                    [
                      _c(
                        "rtb-button",
                        {
                          staticClass: "mr-1",
                          attrs: { variant: "icon", "aria-label": "Edit" },
                          on: {
                            click: function ($event) {
                              return _vm.edit(item)
                            },
                          },
                        },
                        [_c("svg-icon", { attrs: { name: "pen-regular" } })],
                        1
                      ),
                      _c(
                        "rtb-button",
                        {
                          attrs: {
                            color: "danger",
                            variant: "icon",
                            "aria-label": "Delete",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openRemoveDialog(item)
                            },
                          },
                        },
                        [_c("svg-icon", { attrs: { name: "trash-regular" } })],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
          {
            key: "pageText",
            fn: function (ref) {
              var pageStart = ref.pageStart
              var pageStop = ref.pageStop
              return [
                _vm._v(
                  " From " + _vm._s(pageStart) + " to " + _vm._s(pageStop) + " "
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "no-click-animation": "",
            "max-width": "500px",
          },
          model: {
            value: _vm.deleting,
            callback: function ($$v) {
              _vm.deleting = $$v
            },
            expression: "deleting",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "highlight", dark: "" } },
                [_c("v-toolbar-title", [_vm._v(" Delete Twitch Account")])],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  "Are you sure want to delete the twitch account called "
                ),
                _c("b", [_vm._v(_vm._s(_vm.twitch.name))]),
                _vm._v("?"),
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "error",
                          attrs: { flat: "" },
                          on: { click: _vm.remove },
                        },
                        [_vm._v("Remove")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.deleting = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "no-click-animation": "", width: "768px" },
          model: {
            value: _vm.editing,
            callback: function ($$v) {
              _vm.editing = $$v
            },
            expression: "editing",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "highlight", dark: "" } },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [_vm._v("edit")]),
                      _c("v-toolbar-title", [
                        _vm._v(" " + _vm._s(_vm.twitch.name)),
                      ]),
                      _c("v-spacer"),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        { attrs: { icon: "" }, on: { click: _vm.update } },
                        [_c("v-icon", [_vm._v("saves")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.editing = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("cancel")])],
                        1
                      ),
                    ],
                    1
                  ),
                  !!_vm.twitch
                    ? _c(
                        "v-flex",
                        { attrs: { "mt-4": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { column: "" } },
                            [
                              _c(
                                "v-flex",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { "mr-2": "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "TWITCH NAME",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.twitch.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.twitch,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "twitch.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { "ml-2": "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "TWITCH URL",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.twitch.url,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.twitch, "url", $$v)
                                              },
                                              expression: "twitch.url",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { "ml-2": "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "TWITCH API KEY",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.twitch.apiKey,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.twitch,
                                                  "apiKey",
                                                  $$v
                                                )
                                              },
                                              expression: "twitch.apiKey",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: { flat: "" },
                          on: { click: _vm.update },
                        },
                        [_vm._v("Save")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.editing = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }