<template>
  <section>
    <h1 class="sr-only">Twitch Accounts</h1>
    <rtb-card>
      <rtb-card-body>
        <div class="rtb-d-flex align-center">
          <rtb-button
            color="success"
            @click="initTwitchAccountCreation"
            class="mr-2"
            ><svg-icon name="plus-regular" />Add new Twitch Account</rtb-button
          >
          <rtb-text-input
            v-model="search"
            label="Search"
            placeholder="Search"
            hide-label
          />
        </div>
      </rtb-card-body>
    </rtb-card>

    <rtb-table></rtb-table>
    <v-data-table
      :headers="headers"
      :items="getTwitchAccounts"
      :pagination.sync="pagination"
      :search="search"
      class="rtb-table"
      item-key="key"
    >
      <template #items="{ item }">
        <tr>
          <td>
            {{ item.name }}
          </td>
          <td>{{ item.url }}</td>
          <td>{{ item.apiKey }}</td>
          <td>{{ item.status || "unavailable" }}</td>

          <td width="108px">
            <rtb-button
              variant="icon"
              aria-label="Edit"
              class="mr-1"
              @click="edit(item)"
            >
              <svg-icon name="pen-regular" />
            </rtb-button>
            <rtb-button
              color="danger"
              variant="icon"
              aria-label="Delete"
              @click="openRemoveDialog(item)"
            >
              <svg-icon name="trash-regular" />
            </rtb-button>
          </td>
        </tr>
      </template>
      <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        From {{ pageStart }} to {{ pageStop }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="deleting"
      persistent
      no-click-animation
      max-width="500px"
    >
      <v-card>
        <v-toolbar color="highlight" dark>
          <v-toolbar-title> Delete Twitch Account</v-toolbar-title>
        </v-toolbar>
        <v-card-text
          >Are you sure want to delete the twitch account called
          <b>{{ twitch.name }}</b
          >?</v-card-text
        >
        <v-card-actions>
          <v-layout justify-center>
            <v-btn flat class="error" @click="remove">Remove</v-btn>
            <v-btn flat class="secondary" @click="deleting = false"
              >Cancel</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editing" persistent no-click-animation width="768px">
      <v-card>
        <v-card-text>
          <v-toolbar color="highlight" dark>
            <v-icon dark>edit</v-icon>
            <v-toolbar-title> {{ twitch.name }}</v-toolbar-title>
            <v-spacer />
            <v-spacer />
            <v-btn icon @click="update">
              <v-icon>saves</v-icon>
            </v-btn>
            <v-btn icon @click="editing = false">
              <v-icon>cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-flex mt-4 v-if="!!twitch">
            <v-layout column>
              <v-flex>
                <v-layout row>
                  <v-flex mr-2>
                    <v-text-field
                      label="TWITCH NAME"
                      required
                      v-model="twitch.name"
                    />
                  </v-flex>
                  <v-flex ml-2>
                    <v-text-field
                      label="TWITCH URL"
                      required
                      v-model="twitch.url"
                    />
                  </v-flex>
                  <v-flex ml-2>
                    <v-text-field
                      label="TWITCH API KEY"
                      required
                      v-model="twitch.apiKey"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-center>
            <v-btn flat class="primary" @click="update">Save</v-btn>
            <v-btn flat class="secondary" @click="editing = false"
              >Cancel</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import {
  RtbButton,
  RtbCard,
  RtbCardBody,
  RtbTextInput,
  RtbTable
} from "@/components/ui"

export default {
  name: "TwitchPage",
  components: {
    RtbButton,
    RtbCard,
    RtbCardBody,
    RtbTextInput,
    RtbTable
  },
  mounted() {
    this.$store.dispatch("screenshot/subscribeToTwitchAccounts")
  },
  beforeDestroy() {
    this.$store.dispatch("screenshot/unsubscribeToTwitchAccounts")
  },
  data() {
    return {
      twitch: {
        key: 0,
        name: "",
        url: "",
        apiKey: ""
      },
      viewing: false,
      items: [],
      deleting: false,
      editing: false,
      adding: false,
      search: "",
      pagination: { rowsPerPage: 20, page: 1 },
      user: this.$store.getters.user,
      headers: [
        {
          text: "NAME",
          value: "name",
          align: "left",
          sortable: false
        },
        {
          text: "URL",
          value: "url",
          align: "left",
          sortable: false
        },
        {
          text: "API KEY",
          value: "apiKey",
          align: "left",
          sortable: false
        },
        {
          text: "IN USE",
          value: "inuse",
          align: "left",
          sortable: false
        },
        {
          text: "Action",
          value: "action",
          align: "left",
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters("screenshot", ["getTwitchAccounts"])
  },
  watch: {
    editing(to) {
      this.cancel(to)
    },
    viewing(to) {
      this.cancel(to)
    },
    deleting(to) {
      this.cancel(to)
    }
  },
  methods: {
    ...mapActions("screenshot", [
      "addTwitchAccount",
      "updateTwitchAccount",
      "removeTwitchAccount"
    ]),
    gotoEvents() {
      this.$router.push("/events")
    },
    resetForm() {
      this.twitch = {
        key: 0,
        name: "",
        url: "",
        apiKey: ""
      }
    },
    cancel(to) {
      if (to === false) {
        this.resetForm()
      }
    },
    view(twitch) {
      this.viewing = true
      this.twitch = { ...twitch }
    },
    closeModalDetail() {
      this.viewing = false
      this.twitch = null
    },
    openRemoveDialog(twitch) {
      this.twitch = { ...twitch }
      this.deleting = true
    },
    remove() {
      this.removeTwitchAccount(this.twitch.key)
      this.deleting = false
    },
    edit(twitch) {
      this.editing = true
      this.twitch = { ...twitch }
      this.twitchAccounts = this.getTwitchAccounts
    },
    initTwitchAccountCreation() {
      this.editing = true
      this.adding = true
      this.twitch = {
        name: "",
        url: "",
        apiKey: ""
      }
    },
    update() {
      this.twitch.addedBy = this.user.name
      if (this.adding) {
        this.addTwitchAccount(this.twitch)
      } else {
        this.updateTwitchAccount({
          key: this.twitch.key,
          obj: this.twitch
        })
      }
      this.editing = false
      this.adding = false
    },
    save() {
      this.items.push(this.twitch)
      this.resetForm()
      this.adding = false
      console.log("saving")
      this.addTwitchAccount(this.twitch)
    }
  }
}
</script>
